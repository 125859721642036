import React from "react"

import Layout, {KiteLink, SubHeading} from "../components/layout"

const PrivacyPage = () => (
  <Layout title="Privacy & Cookies Policy" >
    <SubHeading>About Kite</SubHeading>
    <p>Kite Development & Consulting Limited is an English company incorporated and registered in England and Wales with company number 08173252 whose registered office is at 70 Rosamund Road, Oxford, OX2 8NX, United Kingdom.</p>


    <SubHeading>About This Page</SubHeading>

    <p>This privacy policy sets out how we use and protect any information that you give Kite Development & Consulting Limited when you use this website.</p>

    <p>Kite Development & Consulting Limited is committed committed to protecting and respecting your privacy. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>

    <p>Kite Development & Consulting Limited may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>


    <SubHeading>How We Use Data</SubHeading>

    <p>We respect your right to own your own data and for that data to be secure and private. This privacy statement explains what data we collect, and for what purposes we use it.</p>

    <p>If Kite chooses to sell, merge, divest or transfer any part of our business at some point in the future, then your data will be transferred to the new business and used in accordance with this privacy policy. The lawful basis for processing in this scenario is legitimate interest, since evolving our company is a legitimate thing for us to do.</p>


    <SubHeading>Information We May Collect From You</SubHeading>

    <p>We may collect name, contact, business and address information including your email address that you provide by contacting us or by filling in forms on our site.</p>


    <SubHeading>What We Do With The Information We Gather</SubHeading>

    <p>Any details you submit via our website, are held and used by us only for the purpose of answering your enquiry or providing services. We do not disclose this data to any other person outside Kite or to any other company.</p>


    <SubHeading>Cookie Policy</SubHeading>

    <p>Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work better, as well as to provide information to the owners of the site. A cookie often contains a unique number, which can be used to recognise your computer when a user of your computer returns to a website that it visited previously.</p>


    <SubHeading>How We Use Cookies</SubHeading>

    <p>This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web for helping us to understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can improve the experience.</p>

    <SubHeading>Other Websites</SubHeading>

    <p>Our site may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>


    <SubHeading>Changes to this policy</SubHeading>

    <p>We keep our privacy policy under regular review and will notify visitors to our website when it is updated.</p>

    <p>This policy was last updated on 12th January 2024.</p>


    <SubHeading>How to contact us</SubHeading>

    <p>If you want to request information about our privacy policy you can email us at <KiteLink href="mailto:kite@madebykite.com">kite@madebykite.com</KiteLink>.</p>
    
  </Layout>
)

export default PrivacyPage
